import React from 'react';
import { Link } from "react-router-dom";

const PortfolioItem = ({ portfolio }) => {
    const isExternalLink = portfolio.btn.link.startsWith("http");

    return (
        <div className="rwt-card">
            <div className="inner">
                <div className="thumbnail">
                    <figure className="card-image">
                        {isExternalLink ? (
                            <a href={portfolio.btn.link} target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src={portfolio.portfolioImage} alt={`Portfolio-${portfolio.id}`} />
                            </a>
                        ) : (
                            <Link to={portfolio.btn.link}>
                                <img className="img-fluid" src={portfolio.portfolioImage} alt={`Portfolio-${portfolio.id}`} />
                            </Link>
                        )}
                    </figure>
                    {isExternalLink ? (
                        <a href={portfolio.btn.link} className="rwt-overlay" target="_blank" rel="noopener noreferrer"></a>
                    ) : (
                        <Link to={portfolio.btn.link} className="rwt-overlay"></Link>
                    )}
                </div>
                <div className="content">
                    <h5 className="title mb--10">
                        {isExternalLink ? (
                            <a href={portfolio.btn.link} target="_blank" rel="noopener noreferrer">
                                {portfolio.title}
                            </a>
                        ) : (
                            <Link to={portfolio.btn.link}>
                                {portfolio.title}
                            </Link>
                        )}
                    </h5>
                    <span className="subtitle b2 text-capitalize">{portfolio.category}</span>
                </div>
            </div>
        </div>
    );
};

export default PortfolioItem;
