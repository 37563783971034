import React from 'react';

const TestimonialData =  {
        id: "01",
        form: "ChatGPT",
        description: "En este chat, podrán explorar sus investigaciones y publicaciones, facilitando la colaboración con interesados a nivel nacional e internacional, así como el apoyo a sus alumnos, todo esto impulsado por la inteligencia artificial de ChatGPT. ¡Y todo completamente gratis!",
        name: "",
        subtitle: "",
        image: "ChatGPT",
    }



const TestimonialTwo = ({column , teamStyle}) => {
    return (
        <div className="row">
            <div className={`${column}`}>
                <div className={`testimonial-style-two ${teamStyle}`}>
                    <div className="row align-items-center row--20">
                        <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                            <div className="content mt_sm--40">
                                <span className="form">{TestimonialData.form}</span>
                                <p className="description">{TestimonialData.description}</p>
                                <div className="client-info">
                                    <h4 className="title">{TestimonialData.name}</h4>
                                    <h6 className="subtitle">{TestimonialData.subtitle}</h6>
                                    <a 
                                        className="btn-default" 
                                        href="https://chatgpt.com/g/g-gyd8HrVjR-bibliografia-eduardo-garcia-maynez" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        <span>¡Da Click!</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="order-1 order-md-2 col-lg-4 col-md-4">
                            <div className="thumbnail">
                                <img className="w-100" src={`./images/brand/${TestimonialData.image}.png`} alt="Corporate React Template" />
                            </div>
                            
                        </div>
                    </div> 
                    
                </div>
            </div>
        </div>
        
    )
}
export default TestimonialTwo;