import React, { useEffect } from 'react';

const Darkmode = () => {
    const switchTheme = (e) => {
        document.querySelector('body').classList.toggle("light");
    }

    // Este useEffect se ejecuta cuando el componente se monta
    useEffect(() => {
        // Agrega la clase 'light' al body por defecto
        document.querySelector('body').classList.add("light");
    }, []);

    return (
        <button id="darkmode" onClick={(e) => switchTheme(e)}>
           <img className="light-icon" src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`} alt="Sun icon" />
           <img className="dark-icon" src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`} alt="Vector icon" />
        </button>
    )
}

export default Darkmode;
