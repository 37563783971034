// Nav.js
import React from 'react';
import { Link } from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="#sobre-mi">Sobre mí</a></li>
            <li><a href="#linea-investigacion">Líneas de investigación</a></li>
            <li><a href="#chatgpt">ChatGPT</a></li>
        </ul>
    )
}
export default Nav;
