import React from 'react';

const BrandList = [
    {
        image: './images/brand/LogoUNAM.png',
        url: 'https://www.derecho.unam.mx/'
    },
    {
        image: './images/brand/LogoCDMX.png',
        url: 'https://www.poderjudicialcdmx.gob.mx/'
    },
    {
        image: './images/brand/LogoPJ.png',
        url: 'https://www.pjedomex.gob.mx/vista/1_inicio'
    },

]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a className="col-lg-8" href={data.url} target="_blank" rel="noopener noreferrer">
                        <img src={`${data.image}`} alt="Brand Image" />
                    </a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
