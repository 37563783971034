import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Sobre Mi<br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Formación Académica",
                                            "Líneas de Investigación",
                                            "Publicaciones",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Lorem ipsum dolor sit amet. Hic iure iste ut quod voluptate est temporibus rerum. Sit sunt neque ut dolorum dignissimos ut doloribus vero! Nam quis quia aut galisum nihil non Quis rerum sit quia doloribus est dolores eveniet 33 repellendus accusamus non similique sunt.

Aut labore enim rem pariatur odio in enim ullam eum voluptate voluptate et quod eveniet et Quis unde qui magni aliquid? Sed internos architecto qui dolor numquam id atque voluptas aut eius officiis? Est ipsum quisquam eum earum voluptas sit corrupti unde.

Qui dolorem architecto hic sunt porro non eaque nihil et consequatur odit sed dignissimos atque. Qui galisum provident et veritatis reprehenderit in quae reiciendis et explicabo Quis id inventore laborum hic corporis consequatur ea repudiandae expedita. Ad porro modi et provident consequuntur id repellat numquam nam rerum vero!</p>
                                <p>Lorem ipsum dolor sit amet. Hic iure iste ut quod voluptate est temporibus rerum. Sit sunt neque ut dolorum dignissimos ut doloribus vero! Nam quis quia aut galisum nihil non Quis rerum sit quia doloribus est dolores eveniet 33 repellendus accusamus non similique sunt.

Aut labore enim rem pariatur odio in enim ullam eum voluptate voluptate et quod eveniet et Quis unde qui magni aliquid? Sed internos architecto qui dolor numquam id atque voluptas aut eius officiis? Est ipsum quisquam eum earum voluptas sit corrupti unde.

Qui dolorem architecto hic sunt porro non eaque nihil et consequatur odit sed dignissimos atque. Qui galisum provident et veritatis reprehenderit in quae reiciendis et explicabo Quis id inventore laborum hic corporis consequatur ea repudiandae expedita. Ad porro modi et provident consequuntur id repellat numquam nam rerum vero!</p>
                                <div className="read-more-btn mt--40">
                                 {/* <a className="btn-default" href="#"><span>More About Us</span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
