// PersonalPortfolio.js
import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import CounterUpFour from '../elements/counterup/CounterUpFour';
import BrandFour from "../elements/brand/BrandFour";
import TestimonialTwo from '../elements/testimonial/TestimonialTwo';
import Typed from 'react-typed';
import TestimonialOne from '../elements/testimonial/TestimonialOne';
import Brevo from '../elements/brevo';

const PersonalPortfolio = () => {
    return (
        <>
            <SEO title="Investigador/Investigadora" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div id="inicio" className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/banner2.png)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Dr./Dra.<br /> <span className="color-primarys">Su nombre (Autor) </span></h1>
                                    <div className="button-group mt--30">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutOne  /> 

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--90 mt_dec--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1" >
                            <h2 className="title inner text-center" >{" "}

                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Conferencista",
                                            "Colaborador/colaboradora",
                                            "Asesor/Asesora ",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <BrandFour id="sobre-mi" brandStyle="brand-style-2 variation-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start Counterup Area  */}
                <div id="linea-investigacion" className="our-counterup-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Start Elements Area  */}
                                <div className="rwt-counterup-area ptb--60">
                                    <div className="col-lg-12">
                                    <SectionTitle
                                    textAlign = "text-center"
                                    title = "Sección de Publicaciones"
                                />
                                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                                        <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio"  />
                                    </div>
                                </div>
                                {/* End Elements Area  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Counterup Area  */}
                <Separator />

                <div id="chatgpt" className="rwt-counterup-area ptb--60">
                    <div className="col-lg-12">
                    <h2 className="title inner text-center" >{"Aprende de mis "}

                            <Typed className="theme-gradient"
                                strings={[
                                    "Publicaciones",
                                    "Investigaciones",
                                ]}
                                typeSpeed={80}
                                backSpeed={5}
                                backDelay={1000}
                                loop
                            />
                        </h2>
                       <TestimonialTwo />
                    </div>
                </div>
                 {/* End Testimonial   */}

                 <div id="chatgpt" className="rwt-counterup-area ptb--60">
                    <div className="col-lg-12">
                    <h2 className="title inner text-center" >{"Investigadores que hemos ayudado"}

                        </h2>
                        <TestimonialOne/>
 
                    </div>
                </div>
                 {/* End Testimonial   */}

                <Separator />
                <Copyright />
                <Brevo/>
            </main>
        </>
    )
}
export default PersonalPortfolio;
